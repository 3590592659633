import logo from "../assets/img/sphinx-game-logo-2.png";
import screen1 from "../assets/img/gamescreen1.png";
import screen2 from "../assets/img/gamescreen2.png";
import { useNavigate } from "react-router-dom";
import { FaTwitter, FaItchIo, FaDiscord } from "react-icons/fa";
import { Breadcrumb } from "../components/Breadcrumb";

import "./RiddlesGame.css";

function RiddlesGame() {
  const navigate = useNavigate();
  return (
    <div className="h-full">
      <header className="riddles-header">
        <Breadcrumb links={[{ name: "Home", url: "/" }]} />
      </header>
      <div class="game-section flex flex-col">
        <img src={logo} className="riddles-logo" alt="logo" />
        <div className="w-2/3 text-3xl text-cyan-400">
          Riddles Of The Sphinx is a riddle based puzzle game that lets players
          compete against each other to find real tresures.
        </div>
        <div className="w-2/3 text-2xl">
          Riddles of the Sphinx Riddles uses single word based puzzles to make
          guessing a Bitcoin pass phrase a game. Riddles are one of the oldest
          forms of entertainment. The first discovered written riddles are over
          4,000 years old. Join the fun and compete against your friends to win
          the real 1 million SATs (0.01 Bitcoin) treasure!
        </div>
        <div className="w-2/3 text-2xl">
          <p className="mt-3">The game is available on <a className="underline" href="https://rabbotxyz.itch.io/">itch.io</a> and is free to play. The game is
          designed to be played in a web browser on a desktop or laptop. The
          game is not optimized for mobile devices.</p>
          {/* <iframe title="itch-game" frameborder="0" src="https://itch.io/embed/2705219?linkback=true&amp;bg_color=222222&amp;fg_color=eeeeee&amp;link_color=e65bfa&amp;border_color=363636" width="552" height="167"><a href="https://rabbotxyz.itch.io/riddles-of-the-sphinx-aero">Riddles Of The Sphinx - Aero by Rabbot Games</a></iframe> */}
        </div>
        <div className="flex flex-row mt-6">
          <img src={screen1} className="screen-img" alt="screen1" />
        </div>
        <div className="w-2/3 text-2xl">
          The game is simple. You are presented with 12 riddles. The answer to
          all 12 of the one word riddles is a Mnemonic seed phrase that
          represents the private key to a Bitcoin address. This gives the first
          person who guesses all the words access to the Bitcoin, which they can
          transfer to their own private address.
        </div>
        <div className="flex flex-row mt-6">
          <img src={screen2} className="screen-img" alt="screen2" />
        </div>
        <div className="w-2/3 text-2xl">
          The answer to each riddle is a single word. You type in your guess
          (one word) and if you are correct you will be presented with the next
          riddle. The goal is to answer all the riddles correctly.
        </div>

        {/* <div className="m-6 p-3 flex flex-row">
          <div className="text-blue-300 text-4xl link-common m-2" onClick={()=>navigate('/riddleshelp')}>
              HELP
          </div>
          <div className="text-blue-300 text-4xl link-common m-2" onClick={()=>navigate('/riddlesblog')}>
              DEVBLOG
          </div>    
        </div> */}
        <div className="flex flex-row mt-6">
          <a href="mailto:rabbotxyz@protonmail.me" class="link-common">
            rabbotxyz@protonmail.me
          </a>
        </div>
        <div className="flex flex-row align-baseline">
          <a
            href="https://discord.gg/pSG6yp5SRS"
            class="flex flex-row link-common"
          >
            <FaDiscord className="mr-2" /> Discord
          </a>
        </div>
        <div className="flex flex-row">
          <a
            href="https://rabbotxyz.itch.io"
            class="flex flex-row link-common"
          >
            <FaItchIo className="mr-2" /> itch.io
          </a>
        </div>
        <div className="flex flex-row">
          <a
            href="https://twitter.com/rabbotxyz"
            class="flex flex-row link-common"
          >
            <FaTwitter className="mr-2" /> twitter
          </a>
        </div>
      </div>
    </div>
  );
}

export default RiddlesGame;
