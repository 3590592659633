import logo from "../assets/img/rabbotxyz.png";

import { FaTwitter, FaDiscord } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Home.css";

function Home() {
  const navigate = useNavigate();
  return (
    <div className="home h-full">
      <header className="home-header">
        <h1 className="text-5xl">rabbot.xyz</h1>
      </header>
      <div className="home-section">
      <img src={logo} className="home-logo" alt="logo" />
        <p>We make cute little games on the blockchain.</p>
        <div className="flex flex-row mt-3">
          <button
            className="btn-common"
            onClick={() => {
              navigate("/riddles");
            }}
          >
            Riddles Of The Sphinx
          </button>
        </div>
        <div className="flex flex-row mt-10">
          <a href="mailto:rabbotxyz@protonmail.me" class="link-common">
            rabbotxyz@protonmail.me
          </a>
        </div>
        <div className='flex flex-row align-baseline'><a href="https://discord.gg/c8uYjxNuVd" class="flex flex-row link-common"><FaDiscord className="mr-2"/> Discord</a></div>
        <div className="flex flex-row">
          <a href="https://twitter.com/rabbotxyz" class="flex flex-row link-common align-baseline">
            <FaTwitter className="mr-2" /> twitter
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
