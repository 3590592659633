import React from "react";
export const Breadcrumb = ({ links }) => {
    return (
        <div className="flex flex-row text-white">
        {links.map((link, index) => {
            return (
            <div key={index} className="flex flex-row">
                <a href={link.url} className="hover:text-blue-400 mr-2">
                {link.name}
                </a>
                {index < links.length - 1 && (<div className="mx-2">/</div>)}
            </div>
            )
        }
        )}
        </div>
    );
}